<script>
import { mapGetters } from 'vuex';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import AntInput from '@/components/AntInput.vue';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';
import axios from 'axios';
import { bookOfObjects } from '@/services/bookOf';
import {
  createRecord,
  deleteRecord,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

export default {
  name: 'DhmeHallConfigurator',
  components: { AntInput, ModuleNavigationBar },
  data: () => {
    return {
      isLoading: false,

      halls: [],
      hallsTableId: null,
      hallListIndex: null,
      focusedHall: null,
      newHallTitle: '',
      newHallMenu: false,

      hallConfigurations: [],
      hallConfigurationsTableId: null,
      focusedConfiguration: null,
      newConfigurationTitle: '',
      newConfigurationMenu: false,

      configLanes: [],
      configLanesTableId: null,
      newLaneTitle: '',
      newLaneColor: '',
      newLaneMenu: false,
      laneIndex: null,
      focusedLane: null,
      updateLaneMenu: false,

      configSteps: [],
      configStepsTableId: null,
      newStepTitle: '',
      newStepMenu: false,
      focusedStep: null,
      updateStepMenu: false,

      configLocations: [],
      configLocationsTableId: null,
      focusedLocation: null,
      newLocationTitle: '',
      newLocationMenu: false,
      locationListIndex: null,

      locationTypes: [],
      locationTypesTableId: null,
      newTypeTitle: '',
      newTypeMenu: false,

      locationActivities: [],
      locationActivitiesTableId: null,
      newActivityTitle: '',
      newActivityMenu: false,
    };
  },
  computed: {
    ...mapGetters(['project', 'isDaiwaLicense']),
    locationsByLane() {
      return this.configLocations
        .filter((l) => l.assembly_lane === this.focusedLane.id)
        .sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    focusedHall(value) {
      if (this.$route.query.hall !== value.id) {
        this.$router.push({ query: { hall: value.id } });
      }
    },
    focusedConfiguration(value) {
      if (this.$route.query.configuration !== value.id) {
        this.$router.push({
          query: { hall: value.assembly_hall, configuration: value.id },
        });
      }
    },
    focusedLane(value) {
      if (this.$route.query.lane !== value.id) {
        this.$router.push({
          query: {
            hall: this.focusedHall.id,
            configuration: this.focusedConfiguration.id,
            lane: value.id,
          },
        });
      }
    },
    focusedLocation(value) {
      if (this.$route.query.location !== value.id) {
        this.$router.push({
          query: {
            hall: this.focusedHall.id,
            configuration: this.focusedConfiguration.id,
            lane: value.assembly_lane,
            location: value.id,
          },
        });
      }
    },
  },
  mounted() {
    this.fetchHalls();
  },
  methods: {
    async translateActivities() {
      // Transforming records to the desired format
      const transformedRecords = this.locationActivities.reduce(
        (acc, record) => {
          acc[record.id] = record.activity;
          return acc;
        },
        {}
      );

      // Sending the request to the translation API
      try {
        const response = await axios.post(
          'https://translate-ant.jw-kattouw.workers.dev/',
          {
            system_prompt:
              'You are tasked with transforming JSON objects where the values are activity names in English. Your goal is to translate the values into four languages: Dutch, English, German, and Polish.',
            user_prompt_prefix:
              "I have a JSON object with activity names, and I want to translate the values into Dutch, English, German, and Polish. The response should be a list of objects where each object contains two properties: 'id', which is the original key from the input JSON, and 'translations', which is an object where the keys are the language abbreviations ('en', 'nl', 'de', 'pl') and the values are the corresponding translations. Please only return the json object and nothing else. Here is the JSON object I want to translate: ",
            message: JSON.stringify(transformedRecords),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        let book = bookOfObjects('records', response.data.parsed_response.data);
        let csv = book.convert('csv', 'string');
        let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

        await importRecords({
          project: {
            id: this.project.id,
          },
          table: {
            id: this.locationActivitiesTableId,
          },
          records: parsedCsv,
        });

        console.log(response.data); // Log the translated response
      } catch (error) {
        console.error('Error translating activities:', error);
      }
    },

    getBlackOrWhiteBasedOnBackground,
    async fetchHalls() {
      this.isLoading = true;
      try {
        const { halls } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_HALLS',
              project: this.project.id,
              as: 'halls',
              columns: [
                {
                  name: 'hall',
                },
                {
                  name: 'type',
                },
                {
                  name: 'description',
                },
                {
                  name: 'branch',
                },
                {
                  name: 'location',
                },
                {
                  name: 'automated',
                },
                {
                  name: 'manual_planning',
                },
              ],
            },
          ],
        });
        this.halls = halls.records;
        this.hallsTableId = halls.id;

        if (this.$route.query.hall) {
          this.focusOnHall(
            this.halls.find((h) => h.id === this.$route.query.hall)
          );
          this.hallListIndex = this.halls.findIndex(
            (h) => h.id === this.$route.query.hall
          );
        }
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnHall(hall) {
      this.focusedHall = { ...hall };
      this.focusedConfiguration = null;
      this.focusedLane = null;
      this.focusedLocation = null;
      this.fetchHallConfigurations();
    },
    async saveNewHall() {
      this.isLoading = true;
      try {
        const hall = await createRecord({
          project: { id: this.project.id },
          table: { id: this.hallsTableId },
          record: {
            hall: this.newHallTitle,
            automated: false,
            type: 'assembly',
            manual_planning: false,
          },
        });
        this.halls.push(hall);
        this.newHallTitle = '';
        this.newHallMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async updateHall() {
      this.isLoading = true;
      try {
        const hall = await updateRecord(this.focusedHall.id, {
          project: { id: this.project.id },
          table: { id: this.hallsTableId },
          record: this.focusedHall,
        });

        Object.assign(
          this.halls.find((h) => h.id === this.focusedHall.id),
          hall
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async fetchHallConfigurations() {
      this.isLoading = true;
      try {
        const { configurations } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
              project: this.project.id,
              as: 'configurations',
              columns: [
                {
                  name: 'assembly_hall',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedHall.id,
                      boolean: 'or',
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'description',
                },
              ],
            },
          ],
        });
        this.hallConfigurations = configurations.records;
        this.hallConfigurationsTableId = configurations.id;

        if (this.$route.query.configuration) {
          this.focusOnConfiguration(
            this.hallConfigurations.find(
              (c) => c.id === this.$route.query.configuration
            )
          );
        }
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewConfiguration() {
      this.isLoading = true;
      try {
        const hall = await createRecord({
          project: { id: this.project.id },
          table: { id: this.hallConfigurationsTableId },
          record: {
            assembly_hall: this.focusedHall.id,
            title: this.newConfigurationTitle,
            description: `${this.newConfigurationTitle} Description`,
          },
        });
        this.hallConfigurations.push(hall);
        this.newConfigurationTitle = '';
        this.newConfigurationMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    focusOnConfiguration(config) {
      this.focusedConfiguration = { ...config };
      this.fetchConfigurationLanesAndLocations();
      this.focusedLane = null;
      this.focusedLocation = null;
    },
    async updateConfiguration() {
      this.isLoading = true;
      try {
        const configuration = await updateRecord(this.focusedConfiguration.id, {
          project: { id: this.project.id },
          table: { id: this.hallConfigurationsTableId },
          record: this.focusedConfiguration,
        });
        Object.assign(
          this.hallConfigurations.find(
            (h) => h.id === this.focusedConfiguration.id
          ),
          configuration
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async fetchConfigurationLanesAndLocations() {
      this.isLoading = true;
      try {
        const { lanes, steps, locations } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_LANES',
              project: this.project.id,
              as: 'lanes',
              sortBy: 'order',
              columns: [
                {
                  name: 'configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'color',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_STEPS',
              project: this.project.id,
              as: 'steps',
              sortBy: 'order',
              columns: [
                {
                  name: 'configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
              project: this.project.id,
              as: 'locations',
              sortBy: 'order',
              columns: [
                {
                  name: 'order',
                },
                {
                  name: 'hall_configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'assembly_location',
                },
                {
                  name: 'assembly_lane',
                },
                {
                  name: 'in_agv_route',
                },
                {
                  name: 'description',
                },
                {
                  name: 'manual_default',
                },
              ],
            },
          ],
        });
        this.configLanes = lanes.records;
        this.configLanesTableId = lanes.id;

        if (this.$route.query.lane) {
          this.focusOnLane(
            this.configLanes.find((c) => c.id === this.$route.query.lane)
          );
        }

        this.configSteps = steps.records;
        this.configStepsTableId = steps.id;

        this.configLocations = locations.records;
        this.configLocationsTableId = locations.id;

        if (this.$route.query.location) {
          this.focusOnLocation(
            this.configLocations.find(
              (c) => c.id === this.$route.query.location
            )
          );
          this.locationListIndex = this.configLocations.findIndex(
            (h) => h.id === this.$route.query.location
          );
        }
      } catch (e) {}

      this.isLoading = false;
    },
    async saveNewLane() {
      this.isLoading = true;
      try {
        const lane = await createRecord({
          project: { id: this.project.id },
          table: { id: this.configLanesTableId },
          record: {
            configuration: this.focusedConfiguration.id,
            title: this.newLaneTitle,
            color: this.newLaneColor,
            order: 0,
          },
        });
        this.configLanes.push(lane);
        this.newLaneTitle = '';
        this.newLaneColor = '';
        this.newLaneMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    updateLaneColor(value) {
      console.log(value);
      this.focusedLane.color = value.hex;
    },
    async updateLane() {
      this.isLoading = true;
      try {
        const lane = await updateRecord(this.focusedLane.id, {
          project: { id: this.project.id },
          table: { id: this.configLanesTableId },
          record: this.focusedLane,
        });
        Object.assign(
          this.configLanes.find((h) => h.id === this.focusedLane.id),
          lane
        );
        this.updateLaneMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteLane() {
      this.isLoading = true;
      try {
        await deleteRecord(this.focusedLane.id, {
          project: { id: this.project.id },
          table: {
            id: this.configLanesTableId,
          },
        });
        this.configLanes = this.configLanes.filter(
          (l) => l.id !== this.focusedLane.id
        );
        this.focusedLane = null;
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnLane(lane) {
      this.focusedLane = { ...lane };
      this.focusedLocation = null;
    },
    async saveNewStep() {
      this.isLoading = true;
      try {
        const lane = await createRecord({
          project: { id: this.project.id },
          table: { id: this.configStepsTableId },
          record: {
            configuration: this.focusedConfiguration.id,
            title: this.newStepTitle,
            order: 0,
          },
        });
        this.configSteps.push(lane);
        this.newStepTitle = '';
        this.newStepMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async updateStep() {
      this.isLoading = true;
      try {
        const lane = await updateRecord(this.focusedStep.id, {
          project: { id: this.project.id },
          table: { id: this.configStepsTableId },
          record: this.focusedStep,
        });
        Object.assign(
          this.configSteps.find((h) => h.id === this.focusedStep.id),
          lane
        );
        this.updateStepMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteStep() {
      this.isLoading = true;
      try {
        await deleteRecord(this.focusedStep.id, {
          project: { id: this.project.id },
          table: {
            id: this.configStepsTableId,
          },
        });
        this.configSteps = this.configSteps.filter(
          (l) => l.id !== this.focusedStep.id
        );
        this.focusedStep = null;
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewLocation() {
      this.isLoading = true;
      try {
        const lane = await createRecord({
          project: { id: this.project.id },
          table: { id: this.configLocationsTableId },
          record: {
            assembly_hall: this.focusedHall.id,
            hall_configuration: this.focusedConfiguration.id,
            assembly_lane: this.focusedLane.id,
            assembly_location: this.newLocationTitle,
            order: 0,
          },
        });
        this.configLocations.push(lane);
        this.newLocationTitle = '';
        this.newLocationMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    updateLocationBtn(column) {
      this.focusedLocation[column] = !this.focusedLocation[column];
      this.updateLocation();
    },
    async updateLocation() {
      this.isLoading = true;
      try {
        const location = await updateRecord(this.focusedLocation.id, {
          project: { id: this.project.id },
          table: { id: this.configLocationsTableId },
          record: this.focusedLocation,
        });
        Object.assign(
          this.configLocations.find((h) => h.id === this.focusedLocation.id),
          location
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteLocation() {
      this.isLoading = true;
      try {
        await deleteRecord(this.focusedLocation.id, {
          project: { id: this.project.id },
          table: {
            id: this.configLocationsTableId,
          },
        });
        this.configLocations = this.configLocations.filter(
          (l) => l.id !== this.focusedLocation.id
        );
        this.focusedLocation = null;
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnLocation(location) {
      this.focusedLocation = { ...location };

      this.fetchLocationTypesAndActivities();
    },
    async fetchLocationTypesAndActivities() {
      this.isLoading = true;
      try {
        const { types, activities } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATION_TYPES',
              project: this.project.id,
              as: 'types',
              sortBy: 'order',
              columns: [
                {
                  name: 'assembly_location',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedLocation.id,
                    },
                  ],
                },
                {
                  name: 'step',
                },
                {
                  name: 'type',
                },
                {
                  name: 'friendly_name',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATION_ACTIVITIES',
              project: this.project.id,
              as: 'activities',
              sortBy: 'order',
              columns: [
                {
                  name: 'assembly_location',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedLocation.id,
                    },
                  ],
                },
                {
                  name: 'step',
                },
                {
                  name: 'activity',
                },
                {
                  name: 'order',
                },
                {
                  name: 'leanforms_remaining_point',
                },
                {
                  name: 'btn_options',
                },
              ],
            },
          ],
        });

        this.locationTypes = types.records;
        this.locationTypesTableId = types.id;

        this.locationActivities = activities.records;
        this.locationActivitiesTableId = activities.id;
      } catch (e) {}
      this.isLoading = false;
    },

    async saveNewType() {
      this.isLoading = true;
      try {
        const type = await createRecord({
          project: { id: this.project.id },
          table: { id: this.locationTypesTableId },
          record: {
            assembly_location: this.focusedLocation.id,
            type: this.newTypeTitle,
            friendly_name: this.newTypeTitle,
            step: '1',
            order: 0,
          },
        });
        this.locationTypes.push(type);
        this.newTypeTitle = '';
        this.newTypeMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewActivity() {
      this.isLoading = true;
      try {
        const activity = await createRecord({
          project: { id: this.project.id },
          table: { id: this.locationActivitiesTableId },
          record: {
            assembly_location: this.focusedLocation.id,
            activity: this.newActivityTitle,
            step: '1',
            order: 0,
          },
        });
        this.locationActivities.push(activity);
        this.newActivityTitle = '';
        this.newActivityMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },

    async updateType(item) {
      this.isLoading = true;
      try {
        const type = await updateRecord(item.id, {
          project: { id: this.project.id },
          table: { id: locationTypesTableId },
          record: item,
        });
        Object.assign(
          this.locationTypes.find((h) => h.id === this.focusedLocation.id),
          type
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteType(item) {
      this.isLoading = true;
      try {
        await deleteRecord(item.id, {
          project: { id: this.project.id },
          table: {
            id: this.locationTypesTableId,
          },
        });
        this.locationTypes = this.locationTypes.filter((t) => t.id !== item.id);
      } catch (e) {}
      this.isLoading = false;
    },

    async updateActivity(item) {
      this.isLoading = true;
      try {
        const activity = await updateRecord(item.id, {
          project: { id: this.project.id },
          table: { id: this.locationActivitiesTableId },
          record: item,
        });
        Object.assign(
          this.locationActivities.find((h) => h.id === this.focusedLocation.id),
          activity
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteActivity(item) {
      this.isLoading = true;
      try {
        await deleteRecord(item.id, {
          project: { id: this.project.id },
          table: {
            id: this.locationActivitiesTableId,
          },
        });
        this.locationActivities = this.locationActivities.filter(
          (t) => t.id !== item.id
        );
      } catch (e) {}
      this.isLoading = false;
    },
    updateColor(value) {
      this.newLaneColor = value.hex;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Hall configurator">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-scroll-height">
      <div
        class="background-white ant-border-right overflow-y-auto"
        style="width: 400px"
      >
        <v-list subheader two-line>
          <v-subheader
            >Halls
            <v-spacer />

            <v-menu v-model="newHallMenu" :close-on-content-click="false" right>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <div class="background-white pa-1">
                <v-text-field
                  v-model="newHallTitle"
                  autofocus
                  filled
                  hide-details
                  placeholder="Title"
                  single-line
                  @keydown.enter="saveNewHall"
                />
              </div>
            </v-menu>
          </v-subheader>
          <v-list-item-group v-model="hallListIndex" color="primary">
            <v-list-item
              v-for="hall in halls"
              :key="hall.id"
              @click="focusOnHall(hall)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ hall.hall }}</v-list-item-title>

                <v-list-item-subtitle
                  >{{ hall.description }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-chip>
                  {{ hall.type }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="flex-grow-1 d-flex flex-column" style="width: 0">
        <v-progress-linear
          v-show="isLoading"
          color="primary"
          height="5"
          indeterminate
        />
        <div v-if="focusedHall" class="d-flex flex-column flex-grow-1">
          <div class="d-flex px-5 py-2 background-white ant-border-bottom">
            <ant-input class="mr-2" label="type" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-model="focusedHall.type"
                  :items="['assembly', 'production']"
                  dense
                  filled
                  hide-details
                  placeholder="type"
                  single-line
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input class="mr-2" label="title" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.hall"
                  dense
                  filled
                  hide-details
                  placeholder="title"
                  single-line
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input class="mr-2" label="description" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.description"
                  dense
                  filled
                  hide-details
                  placeholder="description"
                  single-line
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input class="mr-2" label="branch" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.branch"
                  dense
                  filled
                  hide-details
                  placeholder="branch"
                  single-line
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input class="mr-2" label="location" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.location"
                  dense
                  filled
                  hide-details
                  placeholder="location"
                  single-line
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <v-spacer />
            <ant-input class="mr-2" label="automated" top-margin="mt-0">
              <template #input-field>
                <v-checkbox
                  v-model="focusedHall.automated"
                  class="mt-1"
                  hide-details
                  @change="updateHall"
                />
              </template>
            </ant-input>
          </div>
          <div class="d-flex align-center px-10 pt-2 pb-5 ant-border-bottom">
            <v-subheader class="px-0 mt-5">Configurations</v-subheader>
            <v-menu
              v-model="newConfigurationMenu"
              :close-on-content-click="false"
              right
            >
              <template #activator="{ on, attrs }">
                <v-btn class="mx-2 mt-5" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <div class="background-white pa-1">
                <v-text-field
                  v-model="newConfigurationTitle"
                  autofocus
                  filled
                  hide-details
                  placeholder="Title"
                  single-line
                  @keydown.enter="saveNewConfiguration"
                />
              </div>
            </v-menu>
            <ant-input class="mr-2" label="Configuration" top-margin="mt-0">
              <template #input-field>
                <v-combobox
                  v-model="focusedConfiguration"
                  :items="hallConfigurations"
                  class="mr-2"
                  dense
                  filled
                  hide-details
                  item-text="title"
                  placeholder="configuration"
                  return-object
                  @change="focusOnConfiguration"
                />
              </template>
            </ant-input>
            <v-spacer />

            <ant-input class="mr-2" label="Title" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-if="focusedConfiguration"
                  v-model="focusedConfiguration.title"
                  dense
                  filled
                  hide-details
                  placeholder="title"
                  @change="updateConfiguration"
                />
              </template>
            </ant-input>

            <ant-input class="mr-2" label="Description" top-margin="mt-0">
              <template #input-field>
                <v-text-field
                  v-if="focusedConfiguration"
                  v-model="focusedConfiguration.description"
                  dense
                  filled
                  hide-details
                  placeholder="description"
                  @change="updateConfiguration"
                />
              </template>
            </ant-input>
          </div>
          <div
            v-if="focusedConfiguration"
            class="flex-grow-1 d-flex flex-column"
          >
            <div class="d-flex align-center ant-border-bottom px-5">
              <v-subheader>Lanes</v-subheader>
              <v-menu
                v-model="newLaneMenu"
                :close-on-content-click="false"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <div class="background-white pa-1">
                  <v-text-field
                    v-model="newLaneTitle"
                    autofocus
                    filled
                    hide-details
                    placeholder="Title"
                    single-line
                    @keydown.enter="saveNewLane"
                  />
                  <v-color-picker
                    v-model="newLaneColor"
                    hide-canvas
                    hide-inputs
                    @update:color="updateColor"
                  />
                </div>
              </v-menu>
              <div class="flex-grow-1 overflow-x-auto">
                <v-tabs
                  v-model="laneIndex"
                  background-color="transparent"
                  color="primary"
                  group
                  tile
                >
                  <v-tab
                    v-for="lane in configLanes"
                    :key="lane.id"
                    center-active
                    class="ma-0"
                    style="width: calc(100% - 70px - 52px)"
                    text
                    @click="focusOnLane(lane)"
                  >
                    <v-chip
                      :color="lane.color ?? $vuetify.theme.themes.light.primary"
                      :text-color="
                        getBlackOrWhiteBasedOnBackground(
                          lane.color ?? $vuetify.theme.themes.light.primary
                        )
                      "
                      >{{ lane.title }}
                      <span class="ml-2 fs-10">({{ lane.order }})</span></v-chip
                    >
                  </v-tab>
                </v-tabs>
              </div>

              <v-menu
                v-if="focusedLane"
                v-model="updateLaneMenu"
                :close-on-content-click="false"
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <div class="background-white px-5 py-2">
                  <ant-input label="Title">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLane.title"
                        autofocus
                        dense
                        filled
                        hide-details
                        placeholder="Title"
                        single-line
                      />
                    </template>
                  </ant-input>

                  <ant-input label="Order">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLane.order"
                        autofocus
                        dense
                        filled
                        hide-details
                        placeholder="Order"
                        single-line
                        type="number"
                      />
                    </template>
                  </ant-input>
                  <v-color-picker
                    v-model="focusedLane.color"
                    hide-canvas
                    hide-inputs
                    @update:color="updateLaneColor"
                  />
                  <div class="d-flex">
                    <v-spacer />
                    <v-btn color="primary" small @click="updateLane"
                      >Save
                    </v-btn>
                  </div>
                </div>
              </v-menu>
              <v-btn v-if="focusedLane" icon @click="deleteLane">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div class="d-flex align-center ant-border-bottom px-5">
              <v-subheader>Steps</v-subheader>
              <v-menu
                v-model="newStepMenu"
                :close-on-content-click="false"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <div class="background-white pa-1">
                  <v-text-field
                    v-model="newStepTitle"
                    autofocus
                    filled
                    hide-details
                    placeholder="Title"
                    single-line
                    @keydown.enter="saveNewStep"
                  />
                </div>
              </v-menu>

              <div v-if="focusedStep" class="mr-2">{{ focusedStep.order }}</div>

              <v-select
                v-model="focusedStep"
                :items="configSteps"
                dense
                filled
                hide-details
                item-text="title"
                item-value="id"
                return-object
                style="max-width: 250px"
              >
                <template #item="{ item }">
                  {{ item.order }} - {{ item.title }}
                </template>
              </v-select>
              <v-spacer />
              <v-menu
                v-if="focusedStep"
                v-model="updateStepMenu"
                :close-on-content-click="false"
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <div class="background-white px-5 py-2">
                  <ant-input label="Title">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedStep.title"
                        autofocus
                        dense
                        filled
                        hide-details
                        placeholder="Title"
                        single-line
                      />
                    </template>
                  </ant-input>

                  <ant-input label="Order">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedStep.order"
                        autofocus
                        dense
                        filled
                        hide-details
                        placeholder="Order"
                        single-line
                        type="number"
                      />
                    </template>
                  </ant-input>
                  <div class="d-flex mt-2">
                    <v-spacer />
                    <v-btn color="primary" small @click="updateStep"
                      >Save
                    </v-btn>
                  </div>
                </div>
              </v-menu>
              <v-btn v-if="focusedStep" icon @click="deleteStep">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>

            <div
              v-if="focusedLane"
              class="flex-grow-1 d-flex"
              style="height: 0"
            >
              <div
                class="background-white ant-border-right overflow-y-auto"
                style="width: 300px"
              >
                <v-list subheader two-line>
                  <v-subheader
                    >Locations
                    <v-spacer />
                    <v-menu
                      v-model="newLocationMenu"
                      :close-on-content-click="false"
                      right
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>

                      <div class="background-white pa-1">
                        <v-text-field
                          v-model="newLocationTitle"
                          autofocus
                          filled
                          hide-details
                          placeholder="Title"
                          single-line
                          @keydown.enter="saveNewLocation"
                        />
                      </div>
                    </v-menu>
                  </v-subheader>
                  <v-list-item-group
                    v-model="locationListIndex"
                    color="primary"
                  >
                    <v-list-item
                      v-for="location in locationsByLane"
                      :key="location.id"
                      @click="focusOnLocation(location)"
                    >
                      <span class="fs-20 mr-4">{{ location.order }}</span>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ location.assembly_location }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          >{{ location.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                @click="updateLocationBtn('manual_default')"
                                v-on="on"
                              >
                                <v-icon
                                  >{{
                                    location.manual_default
                                      ? 'mdi-star-four-points-circle'
                                      : 'mdi-star-four-points-circle-outline'
                                  }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Manual default</span>
                          </v-tooltip>

                          <v-tooltip v-if="focusedHall.automated" bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                @click="updateLocationBtn('in_agv_route')"
                                v-on="on"
                              >
                                <v-icon
                                  >{{
                                    location.in_agv_route
                                      ? 'mdi-network'
                                      : 'mdi-network-outline'
                                  }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>AGV location</span>
                          </v-tooltip>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <div v-if="focusedLocation" class="overflow-y-auto flex-grow-1">
                <v-subheader>General</v-subheader>
                <div class="d-flex px-5 justify-start">
                  <ant-input class="mr-4" label="Location Nr" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.order"
                        :min="0"
                        dense
                        filled
                        hide-details
                        label="number"
                        single-line
                        type="number"
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <ant-input class="mr-4" label="Title" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.assembly_location"
                        dense
                        filled
                        hide-details
                        label="title"
                        single-line
                        type="text"
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <ant-input label="Description" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.description"
                        dense
                        filled
                        hide-details
                        label="description"
                        single-line
                        type="text"
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <v-spacer />
                  <v-btn class="mt-5" icon @click="deleteLocation">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-subheader
                  >Types
                  <v-spacer />
                  <v-menu
                    v-model="newTypeMenu"
                    :close-on-content-click="false"
                    right
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-text-field
                        v-model="newTypeTitle"
                        autofocus
                        filled
                        hide-details
                        placeholder="Title"
                        single-line
                        @keydown.enter="saveNewType"
                      />
                    </div>
                  </v-menu>
                </v-subheader>
                <v-data-table
                  :headers="[
                    { text: 'Element Type', value: 'type' },
                    { text: 'Friendly Name', value: 'friendly_name' },
                    { text: 'Step', value: 'step' },
                    { text: 'Order', value: 'order' },
                    { text: '', value: 'actions' },
                  ]"
                  :items="locationTypes"
                >
                  <template #item.type="{ value, item }">
                    <v-text-field
                      v-model="item.type"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.friendly_name="{ value, item }">
                    <v-text-field
                      v-model="item.friendly_name"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.step="{ value, item }">
                    <v-select
                      v-model="item.step"
                      :items="configSteps"
                      :value="value"
                      dense
                      filled
                      hide-details
                      item-text="title"
                      item-value="id"
                      single-line
                      type="number"
                      @change="updateType(item)"
                    >
                      <template #item="{ item }">
                        {{ item.order }} - {{ item.title }}
                      </template>
                    </v-select>
                  </template>
                  <template #item.order="{ value, item }">
                    <v-text-field
                      v-model="item.order"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      type="number"
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.actions="{ item }">
                    <v-btn icon @click="deleteType(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-subheader
                  >Activities
                  <v-spacer />
                  <v-menu
                    v-model="newActivityMenu"
                    :close-on-content-click="false"
                    right
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-text-field
                        v-model="newActivityTitle"
                        autofocus
                        filled
                        hide-details
                        placeholder="Title"
                        single-line
                        @keydown.enter="saveNewActivity"
                      />
                    </div>
                  </v-menu>
                  <!--                  <v-btn-->
                  <!--                    color="primary"-->
                  <!--                    elevation="0"-->
                  <!--                    x-small-->
                  <!--                    @click="translateActivities"-->
                  <!--                    >Translate</v-btn-->
                  <!--                  >-->
                </v-subheader>
                <v-data-table
                  :headers="[
                    { text: 'Activity', value: 'activity' },
                    { text: 'Step', value: 'step' },
                    { text: 'Order', value: 'order' },
                    {
                      text: 'LF Remaining point',
                      value: 'leanforms_remaining_point',
                    },
                    { text: 'Options', value: 'btn_options' },
                    { text: '', value: 'actions' },
                  ]"
                  :items="locationActivities"
                >
                  <template #item.activity="{ value, item }">
                    <v-text-field
                      v-model="item.activity"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.step="{ value, item }">
                    <v-select
                      v-model="item.step"
                      :items="configSteps"
                      :value="value"
                      dense
                      filled
                      hide-details
                      item-text="title"
                      item-value="id"
                      single-line
                      type="number"
                      @change="updateActivity(item)"
                    >
                      <template #item="{ item }">
                        {{ item.order }} - {{ item.title }}
                      </template>
                    </v-select>
                  </template>
                  <template #item.order="{ value, item }">
                    <v-text-field
                      v-model="item.order"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      type="number"
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.leanforms_q_nr="{ value, item }">
                    <v-text-field
                      v-model="item.leanforms_q_nr"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.leanforms_remaining_point="{ value, item }">
                    <v-text-field
                      v-model="item.leanforms_remaining_point"
                      :value="value"
                      dense
                      filled
                      hide-details
                      single-line
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.btn_options="{ value, item }">
                    <v-select
                      v-model="item.btn_options"
                      :items="[
                        { text: 'Yes', value: '1' },
                        { text: 'Yes & No', value: '2' },
                        { text: 'Yes & Cancel', value: '3' },
                        { text: 'Yes & No & Cancel', value: '4' },
                      ]"
                      :value="value"
                      dense
                      filled
                      hide-details
                      item-text="text"
                      item-value="value"
                      single-line
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.actions="{ item }">
                    <v-btn icon @click="deleteActivity(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
